<template>

  <div class="arrow"></div>

</template>

<script setup>

</script>

<style lang="scss" scoped>

@keyframes scroll-down {
  0%{
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 90%;
  }
}
.arrow {
  height: 50px;
  width: 30px;
  position: absolute;
  bottom: 30px;
  left: 46%;
  //right: 45%;
  border: 2px solid var(--color-light);
  border-radius : 50px;
  cursor: pointer;
  &::before, &::after {
    content: '';
    position: absolute;
    top: 20%;
    left: 50%;
    border: 2px solid var(--color-light);
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
  }
  &::after {
    top: 30%;
    animation-delay: .3s;
  }
 
}
</style>
