<template>
  <div ref="scrollRef">
    <div v-if="error">{{ error }}</div>
    <div v-else>
      <div class="heading">
        <div class="detail__title" >
          <h1>{{ activity.attributes?.Name }}</h1>
          <router-link class="back" to="/Activites"><Icon icon="fa6-solid:chevron-left" class="back__icon"/> Retour</router-link>
        </div>
        <div class="heading__body">
          <div class="detail__intro ck-content" v-html="activity.attributes?.Introduction"></div>
          <!-- <div class="aside__contact">
            <h3>Réserver</h3>
            <p>Pour réserver cette activité ou simplement avoir davantage d'informations</p>
            <router-link :to="{ name: 'Contact' }" class="btn__CTA">Contactez-nous</router-link>
          </div> -->
          <BlocContact />
        </div>

      </div>

      <div class="detail__container">
        <div class="detail__frame" v-for="illustration in illustrations" :key="illustration.index">
          <img class="detail__img" :src="URL +`${illustration.attributes.url}`" :alt="illustration.attributes.alternativeText">
        </div>
      </div>
      <h2>Au programme</h2>
      <div class="detail__container">
        <div class="detail__text">
          <div class="ck-content" v-html="activity.attributes?.Program"></div>
          <div v-if="themes.length">
            <h3 id="themes">Thèmes</h3>
            <CarouselVue :navigation="true" :pagination="true" :startAutoPlay="false" :timeout="5000" class="carousel" 
              :themes="themes" v-slot="{ currentSlide }">
              <SpecialTheme v-for="(theme, index) in themes" :key="index">
                <div v-show="currentSlide === index + 1" @click="toggleSlide(index)">
                  <div class="carousel__slide">
                    <h4 class="carousel__title"> {{ theme.Theme }}</h4>
                    <img class="carousel__img" :src="URL +`${theme.Image.data.attributes.url}`" :alt="theme.Image.data.attributes.alternativeText">
                  </div>
                  <div v-if="flipped === index" class="carousel__slide card-back">
                    <h4 class="backTitle">{{ theme.Theme }}</h4>
                    <p v-html="theme.Description"></p>
                  </div>
                </div>
              </SpecialTheme>
            </CarouselVue>
          </div>
        </div>
        <div class="aside" >
          <BackPack 
          :activity="activity"
          />
          <!-- <router-link to="#" class="aside__sac">
            <Icon icon="noto:backpack" width="128" height="128"  />
            <h3 class="link">Que mettre dans le sac d'aventure&nbsp;?</h3>
          </router-link> -->
          <router-link class="faq-link link" :to="{ name: 'FAQ' }"><Icon class="footer__icon" icon="mdi:frequently-asked-questions" width="34" height="34" /> FAQ</router-link>
          <div class="aside__pricing">
            <div class="text">
              <h3>Tarifs</h3>
              <p v-html="activity.attributes?.PriceDescription"></p> 
            </div>
          </div>
          <BlocContact />
        </div>
      </div>
      

      <div class="ccl">
        <p>Avec joie, à bientôt au vert !</p> 

        <div>
          <p>Aussi, n’hésitez pas à planter des graines de vert autour de vous en offrant la nature avec
          <router-link class="link" :to="{ name: 'Home', hash: '#giftcard' }">les cartes cadeau Pouce&nbsp;!</router-link></p>
          <router-link :to="{ name: 'Home', hash: '#giftcard' }"><img class="gift__img" src="@/assets/img/cadeau_stage.jpg" alt="Carte cadeau Duo Famille de Pouce"></router-link>
        </div>
        <p>La nature, ça se partage&nbsp;!</p>
      </div>
    </div>
  </div>
  
</template>

<script>
import { onMounted, onUnmounted, ref, inject } from 'vue'
import BlocContact from '@/components/BlocContact.vue'
import CarouselVue from '@/components/CarouselVue.vue'
import BackPack from '@/components/BackPack.vue'
import onIntersect from '@/composables/onIntersect.js'
import SpecialTheme from '@/components/SpecialTheme.vue'
import { Icon } from '@iconify/vue';
// import { computed } from '@vue/reactivity'
//import { useHead } from '@vueuse/head'


export default {
  name: "DetailsVue",
  components: { 
    CarouselVue, 
    SpecialTheme, 
    Icon, 
    BlocContact,
    BackPack 
  },
  props: {
    slug: { required: true },
  },

  setup(props) {
    //console.log(props)
    const spin = inject('spin')
    spin.value = true
    const qs = require('qs')
    const URL = process.env.VUE_APP_BASE_URL
    const activity = ref({})
    const illustrations = ref([])
    const themes = ref([])
    const error = ref("")

    const flipped = ref()
 
    
    const scrollRef = ref({})
    const observer = ref({})
    const headerBg = document.querySelector("header")
    const bell = document.querySelector(".sound")

    const query = qs.stringify({
      populate: [
        'Illustrations',
        'SpecialTheme',
        'SpecialTheme.Image',
        'SpecialTheme.Image.media',
      ],
    }, {
      encodeValuesOnly: true,
    })

    const promiseActivity = fetch(process.env.VUE_APP_BASE_URL + `/api/activities/${props.slug}?${query}`)

  
    promiseActivity
    .then(response => {
      
      if(!response.ok){
        throw Error("Cette activité n'existe pas !")
      }
      return response.json()
    })
    .then(activityData => {
      spin.value = false
      //console.log("activite", activityData)
      activity.value = activityData.data
      illustrations.value = activityData.data.attributes.Illustrations.data
      themes.value = activityData.data.attributes.SpecialTheme
     
    })
    .catch (err => {
      error.value = err.message
      console.log(error.value)
    }) 

   

    const onEnter = () => {
      headerBg.classList.add("nav-scrolled")
      bell.classList.add("contrast")
    }

    const onExit = () => {
      headerBg.classList.add("nav-scrolled")
      bell.classList.remove("contrast")
    }


    const toggleSlide = (idx) => {
      flipped.value === idx ? (flipped.value = null) : (flipped.value = idx)
     
    }
  

    onMounted(() => {
      observer.value = onIntersect(scrollRef.value, onEnter, onExit, false, {
        rootMargin: "-100px 0px 0px 0px",
      })

    })

    
    onUnmounted(() => {
      observer.value.disconnect()
    })

    return { 
      activity, 
      error, 
      illustrations, 
      themes, 
      flipped,
      URL,
      toggleSlide,
      scrollRef, 
      observer
    };
  }
}
</script>

<style lang="scss" scoped>


h3 {
  color: var(--color-primary-light);
  margin: 1rem;
}

.heading {
  padding-top: 90px;
  margin: 0 4%;
  &__body {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    @media(max-width: 991px) {
      flex-direction: column;
      gap: 0;
    }
  }


}


.detail {
  &__intro {
   
    text-align: left;
    padding: 1rem;
    //margin: 2rem;
    font-size: 21px;
    
  }
  &__text {
    width: 60%;
    line-height: 150%;
    text-align: left;
    padding: 3rem 2rem 0 2rem;
    margin-top: 1rem;
    //font-size: 21px;
    @media(max-width: 991px) {
      //gap: 10px;
      padding: 10px;
      width: 100%;
      //margin: 1rem;
    }
  }
  &__container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 2rem;
    //padding-left: 2rem;
    margin: 45px;
    @media(max-width: 768px) {
      gap: 10px;
      padding: 15px;
      margin: .5rem;
    }
    &:nth-of-type(3) {
      @media(max-width: 991px) {
        flex-direction: column;
        justify-content: center;
        
      }
    } 

  }
  &__frame {
    float:left;
    position: relative;
    width: 30%;
    padding-bottom: 30%;
    margin: 1.66%;
    overflow: hidden;
  }
  &__img {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
  }
 

}

h3#themes {
  text-align: center;
  padding: 1rem;
  margin-top: 2rem;
}
.detail__info > .detail__text {
  margin-right: auto;
}
a.link {
  color: var(--color-secondary);
  font-weight: bold;
  position: relative;
  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom:0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }
}
.back {
  text-decoration: none;
  color: var(--color-secondary);
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  position: absolute;
  top: 100px;
  left: 3rem;
  cursor: pointer;
  &__icon {
    position: relative;
    bottom: -3px;
  }
}
.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 620px;
  //margin-bottom: 72px;
  position: relative;

  &__slide { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //z-index: 1;
    width: 360px !important;
    max-width: 100%;
    height: 100%;
    background-color: var(--color-lighter);
    overflow: hidden;
    border-radius: 1.4rem;
    box-shadow:  4px 4px 7px -7px rgba(0,0,0,0.75);
    cursor: pointer;
    &:hover > .carousel__title {
        background-color: rgba(232, 63, 111, 0.75);
      }
    img {
      height: 100%;
      //max-height: 100%;
      //min-width: 100%;
      width: 100%;
      
      object-fit: cover;
      border-radius: 1.4rem;
      position: relative;
 
    }
    .carousel__title {
      position: absolute;
      z-index: 1;
      top: 42%;
      left: 0;
      right: 0;
      text-align: center;
      //transform: translate(-50%, -50%);
      background-color: rgba(255, 254, 246, 0.75);
      padding: 18px;
      white-space: nowrap;
      //width: 75%;
      border-radius: 1.4rem;
      font-size: 1.3rem;
      color: var(--color-dark);
      text-align: center;
      transition: .3s ease all;
      @media(max-width: 991px) {
        width: 50%;
        transform: translateX(50%);
        top: 45%;
        left: 0;
        right: 0;
        
        
      }
    }
  }
  .card-back {
    padding: 1rem;
    
   
    @media(max-width: 768px) {
      font-size : 16px;
      line-height:normal;
      padding: 10px;
    }
    .backTitle h4 {
      font-size: 22px;
      padding: 8px;
      //margin: 0 0.7rem 0.7rem 0.7rem;
      color: var(--color-primary-light);
    }
  
  }
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter, .flip-leave {
  transform: rotateY(180deg);
  opacity: 0;

}



.aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 40%;
  @media(max-width: 991px) {
    //flex-direction: row;
    width: 100%;
    margin-top: 2rem;
    padding: 20px;
  }
 
  //padding: 2rem;
  &__contact {
    width: 100%;
    max-width: 550px;
    text-align: center;
    padding: 1rem 2rem 2rem;
    background-color: #549370;
    //max-width: 450px;
    color: var(--color-light);
    font-weight : 600;
    h3 {
      color: var(--color-light);
    }
    p {
      padding: 1rem 0;
    }
  }
  // &__sac {
  //   padding: 3rem 2rem 5rem;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2rem;
  //   align-items: center; 
  // }
  &__pricing {

    width: 100%;
    max-width: 415px;

    background-color: var(--color-tertiary);
    border-radius: 50%;
    line-height: 0;
    position: relative;
    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    .text {
      position: absolute;
     
      width: 100%;
      bottom: 25%;
      padding: 10px;
      top: 15%;
      text-align: center;
    }
    h3 {
      margin: 1.5rem 0.5rem;
      
      
    }
    & > p {
      margin: 0.7rem;
     
    }
   
  }
  
  
}

.faq-link.link {
  padding: 2rem;
  color: var(--color-primary)
}
.ccl {
  width: 80%;
  //padding: 5rem;
  margin: 1rem auto 3rem;
  text-align: center;
  line-height: 150%;
  font-weight: var(--font-medium);
  font-size: 24px;
  @media(max-width: 768px) {
    //gap: 10px;
    width: 100%;
    padding: 20px;
    
    font-size: 21px;
  }
  .gift__img {
    width: 200px;
    margin: 0 auto;
    border-radius: 35px;
    padding: 1em;
    transform: rotate(-0.02turn);
  }
}
@media(max-width: 991px) {
  .aside__pricing {
    width: 50%;
    min-width: 350px;
  }
}
@media(max-width: 768px) {
  h1 {
    font-size: 27px;
    margin: .5rem;
    padding: .5rem;
  }
  .back {
    top: 100px;
    font-size: 18px;
  }
  .heading {
    padding-top: 120px;
  }

  .detail {
    &__intro {
      font-size: 16px;
      padding: 1rem;
      margin: 0;
      h3{
        font-size: 20px;
        
      }
    }
    &__text {
      font-size: 16px;
      padding: 0;
    }
    &__container {
      padding: 0;
    }
  }
  .aside{
    padding: 0;
    &__contact {
      width: 100%;
      font-size: 18px;
      padding: 24px 16px 16px;
      margin-bottom: 2rem;
      h3 {
        margin: 0;
      }
    }
    &__pricing {
      width: 100%;
      max-width: 375px;
    } 
    // &__sac {
    //   padding-bottom: 2rem;
    // }
   
  }
 
  // .carousel__slide {
  //   width: unset;
  //   width: 360px;
    
  // }
  
  
   
 
}
</style>
