<template>
  <footer class="footer">
    <h3>SUIVEZ-NOUS</h3>
    <div class="footer__gallery short">
      <img v-for="(image, index) in images" :key="index" :src="`${URL + image.attributes.formats.thumbnail.url}`" :alt="image.attributes.alternativeText" class="footer__img">     
    </div>
    <div class="footer__gallery">
      <router-link class="faq-link" :to="{ name: 'FAQ' }"><Icon class="footer__icon" icon="mdi:frequently-asked-questions" color="white" width="34" height="34" />FAQ</router-link>
      <a href="https://www.instagram.com/pouce_pausenature.montessori/"><Icon class="footer__icon" icon="cib:instagram" color="#fffef6" height="34"  width="34"/></a>
      <a href="https://www.facebook.com/POUCENature"><Icon class="footer__icon" icon="cib:facebook-f" color="#FFFEF6" height="34" width="34" /></a>
      <a href="https://www.linkedin.com/in/anne-jeanne-etienbled-montessori/"><Icon class="footer__icon" icon="cib:linkedin-in" color="#fffef6" height="34" width="34" /></a>
    </div>
    <p>
      <small><a href="mailto:syrine.snoussi@codeplume.fr">Conception web : codeplume</a></small>
    </p>
   
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue';
import { ref } from 'vue';
export default {
  name: 'FooterCom',
  components: {
    Icon,
  },
  setup ()  {
    const error = ref(null)
    const images = ref()

    const URL = process.env.VUE_APP_BASE_URL
    const run = () => {
      const promiseGalery = fetch(URL + '/api/footer-imgs?populate=*')
      promiseGalery
      .then(response => {
      
        if(!response.ok) {
          throw Error("Aucune donnée !")
        }
        return response.json()
      })
      .then(galleryData => {
        //console.log(galleryData)
        images.value = galleryData.data[0].attributes.Photos.data
        
      })
      .catch(err => {
        error.value = err.message
        console.log(error.value)
      })
    }

    run()

    return {
      error, images, URL
    }
  }
 }
</script>


<style lang="scss" scoped>

.footer {
  background-color: var(--footer-bg-color);
  padding: 20px;
  color: var(--color-light);
 
  &__gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    //flex-wrap: wrap;
    //text-align: center;
    //width: 60%;
    //height: 150px;
    //border: 2px solid yellow;
  }
  &__img {
    //min-width: 100%;
    width: 150px;
    height: 150px;
    //border: 2px solid violet;
    margin: 30px 5px;
  }
  &__icon {
    margin: 20px;
    cursor: pointer;
    
    :hover {
      color:var(--color-primary-light) ;
    }

  }
}
.faq-link {
  svg {
    margin: 0 20px;
  }
  color: var(--color-light);
  //border: 2px solid sandybrown;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: sp;
}
small a {
  padding: 1em;
  margin-left: auto;
  // text-align: right;
  color: grey;
  font-weight: normal;
  hover {
    cursor: pointer;
  }
}
p {
  text-align: right;
}
@media(max-width: 990px) {
  div.short>img:nth-last-child(-n+2) {
    display: none;
  }
  .footer__img {
    margin: 30px 10px;
  }
}
@media(max-width: 767px) {
  div.short>img:nth-last-child(-n+4) {
    display: none;
  }
  
}

</style>
