import { onMounted, onBeforeUnmount } from "vue";

/**
 * 
 * @param {*} el_target_ref The Root element for which cliking outside will trigger callback_fn
 * @param {*} callback_fn The function to call when user clicks outside of
 * @returns
 */
export function useClickOutside (el_target_ref, callback_fn){
    if ( ! el_target_ref ) return;

    let listener = (e) => {
        if ( ! e.target == el_target_ref.value || e.composedPath().includes(el_target_ref.value)){
            //We clicked inside the modal/active region
            return
        }
        //at this point we clicked outside the modal
        if ( typeof callback_fn == 'function') {
            callback_fn();
        }
    }
    onMounted(() => {
        window.addEventListener('click', listener)
    })
    onBeforeUnmount(() => {
        window.removeEventListener('click', listener)
    })
    return {
        listener
    }

}
export default useClickOutside