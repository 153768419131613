<template>
  <a href="#sac" class="sac__icon" @click="toggleSidebar">
    <Icon icon="noto:backpack" width="128" height="128"  />
    <h3 class="link">Que mettre dans le sac d'aventure&nbsp;?</h3>
  </a>
  <div id="sac" v-if="showDrawer">
    <div ref="inner_drawer" class="drawer">
      <button @click="closeDrawer" class="button__close" aria-label="Close alert" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
      <h3>{{ activity.attributes.sac.data.attributes.Titre }}</h3>
      <img src="../assets/img/explore.jpeg" alt="Dessin d'une enfant en bottes de pluie partant marcher dans la nature.">
      <div v-html="activity.attributes.sac.data.attributes.Contenu" class="ck-content"></div>
      <div class="sac__infos">
        <h4>
          <img src="../assets/img/icone_tique.png" alt="Contre les tiques" >
          Info Tiques avant une sortie nature estivale
        </h4>
        <p>Vos enfants vont effectuer une sortie avec POUCE! en pleine nature. En forêt, comme dans les parcs et jardins, les tiques sont présentes en été. Elles ne sont pas dangereuses si on les retire rapidement. En prévention, pensez à habiller les enfants avec <strong>pantalons longs et baskets</strong> (même s’il fait chaud, pas de robes ou de short ni de sandales svp), de préférence <strong>clairs</strong> (on les voit mieux), avec le pantalon qui rentre dans les chaussettes (les tiques ont du style ! ;). Vous pouvez aussi vaporiser un répulsif anti-tiques sur les vêtements avant la sortie (en vente en pharmacie ou magasin bio).</p>
        
      </div>
      <p class="ccl">Au plaisir de partager ces moments Nature et festifs avec vous !</p>
      <p class="ccl"><i>«Pouce !»</i></p>
    </div>
   
  </div>
</template>

<script setup>
import { ref, 
  defineProps 
} from 'vue'
import { Icon } from '@iconify/vue';

const props = defineProps({
  activity: Object
})
props.activity
//console.log(props.activity)

  const showDrawer = ref(false)

  const toggleSidebar = () => {
    showDrawer.value = true
    //document.body.style.overflow = "hidden"
  }
  const closeDrawer = () => {
    showDrawer.value = false
    //document.body.style.overflow = "auto"
  }
 

  

</script>

<style lang="scss" scoped>
.link {
  color: var(--color-primary);
  font-weight: 600;
}
.sac {
  &__icon {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center; 
  }
  &__infos {
    // font-size: 16px;
    padding: 2rem 0;
    margin: 1rem 0;
    img {
      width: 42px;
      display: inline-block;

    }
    h4 {
      //text-align: center;
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;
    }
  }
}
.ccl {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 22px;

}
@media(max-width: 768px) {
  .sac__icon {
    padding-bottom: 2rem;
    
  }
  .sac__infos h4 {
    font-size: 18px;
  }
  .ccl {
    text-align: center;
  }
}
</style>
