<template>
  <div class="slide">
    <Transition name="slide-flip">
      <slot />
    </Transition>
  </div>
 
</template>

<script>


export default {
  
  setup() {

  }
   
}
</script>

<style lang="scss">

// Sliding
.slide-flip-enter-active,
.slide-flip-leave-active {
  transition: opacity 0.7s ease-in-out;
}
.slide-flip-enter-from,
.slide-flip-leave-to {
  opacity: 0;
}

.slide-flip-enter-to,
.slide--flip-leave-from {
  opacity: 1;
}

</style>
