<template>
  <div class="carousel">
    <slot :currentSlide="currentSlide" />

    <!-- Navigation -->
    <div v-if="navEnabled" class="navigate">
      <div class="navigate__toggle left">
        <Icon  class="navigate__icon" @click="prevSlide" icon="fa6-solid:chevron-left"/>
      </div>
      <div class="navigate__toggle right" >
        <Icon class="navigate__icon" @click="nextSlide" icon="fa6-solid:chevron-right" />
      </div>
    </div>

    <!-- Pagination -->
    <div class="pagination">
      <span 
        @click="goToSlide(index)"
        v-for="(slide, index) in getSlideCount" 
        :key="index"
        :class="{ active: index + 1 === currentSlide }"
        class="dot">
      </span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Icon } from '@iconify/vue';




export default {
  props: ["startAutoPlay", "timeout", "navigation", "pagination"],
  setup(props) {
    const currentSlide = ref(1)
    
    const getSlideCount = ref(null)

    const autoPlayEnabled = ref(
      props.startAutoPlay === undefined ? true : props.startAutoPlay
    )
    const timeoutDuration = ref(props.timeout === undefined ? 5000 : props.timeout)
    
    const paginationEnabled = ref(
      props.pagination === undefined ? true : props.pagination
    )
    const navEnabled = ref(
      props.navigation === undefined ? true : props.navigation
    )

    // next Slide
    const nextSlide = () => {
      if (currentSlide.value === getSlideCount.value) {
        currentSlide.value = 1
        return
      }
      currentSlide.value += 1
    }

    // prev Slide
    const prevSlide = () => { 
      if(currentSlide.value === 1) {
        currentSlide.value = 1
        return
      }
      currentSlide.value -=1
    }

    //navigation

    const goToSlide = (index) => {
      currentSlide.value = index + 1
    }

    // autoplay
    const autoplay = () => {
      setInterval(() => {
        nextSlide()
      }, timeoutDuration.value)
    }

    if (autoPlayEnabled.value) {
      autoplay()
    }



    onMounted(() => {
      getSlideCount.value = document.querySelectorAll('.slide').length
    })

    return {
      currentSlide,
      nextSlide,
      prevSlide,
      getSlideCount, 
      goToSlide, 
      paginationEnabled, 
      navEnabled,
    }

  },
  components: { Icon }
}
</script>

<style lang="scss">
.navigate {
  padding: 0 16px;
  height: 100%;
  //max-height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 991px) {
    padding: 0px ;
    //max-height: 700px;
    
  }
  &__toggle {
    display: flex;
    flex: 1;
    z-index: 2;
  }
  .right {
    justify-content: flex-end;
  }
  &__icon {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: var(--color-secondary);
   
  }
  
  
}

.pagination {
  position: absolute;
  bottom: 8px  !important;
  width: 100%;
  //height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  //z-index: 10;
  gap: 8px;
  span {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgba(255, 254, 246, 0.85);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: lightgrey;
   
  }
  .active {
    background-color: var(--color-secondary);
  }
  @media(max-width: 490px) {
    visibility: hidden;
  }
}

</style>
