import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from "@unhead/vue"
import '@/assets/styles/global.scss'
import '@/assets/styles/flower.css'

const head = createHead()

createApp(App).use(head).use(router).mount('#app')
