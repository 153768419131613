<template>
  <div class="aside__contact">
    <h3>Réserver</h3>
    <p>Pour réserver cette activité ou simplement avoir davantage d'informations</p>
    <router-link :to="{ name: 'Contact' }" class="btn__CTA">Contactez-nous</router-link>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.aside__contact {
  width: 100%;
  max-width: 550px;
  text-align: center;
  padding: 2rem;
  background-color: #549370;
  //max-width: 450px;
  color: var(--color-light);
  font-weight : 600;
  h3 {
    color: var(--color-light);
  }
  p {
    padding: 1rem 0;
  }
}
a.btn__CTA {
  display: block;
}

</style>
