<template>
  <section id="home" class="full-height">
    <div class="home__container" >
      <span class="home__text" ref="scrollRef" ><strong><em>"Pouce&nbsp;!"</em></strong></span><br/>
      <span class="home__text" ref="scrollRef" >VOICI DES PAUSES <strong>NATURE & MONTESSORI</strong></span>
      <span class="home__text"> QUI TITILLENT <strong>LES CURIOSITÉS,</strong> ENTRETIENNENT</span>
      <span class="home__text"><strong>L'ÉMERVEILLEMENT</strong> ET NOUS <strong>RELIENT</strong> À NOTRE NATURE.</span>
      <span class="home__text">VIVONS DES MOMENTS <strong>PASSIONNÉS</strong> ET PASSIONNANTS</span>
      <span class="home__text">EN <strong>HARMONIE</strong> AVEC NOUS-MÊMES, LES AUTRES,</span>
      <span class="home__text"> LA <strong>NATURE</strong>... BREF LE VIVANT !</span><br/>
      <a href="#actualites">
        <ArrowButton />
      </a>
    </div>
  </section>

    
  <ActualityVue />
  
  <section id="sayings" >  
    <h2 id="liked">Paroles de semeurs</h2> 
    <div class="advices" v-if="temoignages.length">
      <figure class="advices__div" v-for="(temoignage, index) in temoignages" :key="index">
        <div class="circle"><Icon icon="bxs:quote-alt-left" width="36" height="36"/></div>
        <blockquote> {{ temoignage.attributes.commentaire }}</blockquote>
        <figcaption> {{ temoignage.attributes.nom }}</figcaption>
      </figure>
    </div>
    <router-link class="btn__CTA" :to="{ name: 'Activities' }">Voir nos activités</router-link>
  </section>
  
  <GiftCard /> 
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import { onIntersect } from '@/composables/onIntersect.js'
import { Icon } from '@iconify/vue';

import GiftCard from '@/components/GiftCard.vue';
import ActualityVue from '@/components/ActualityVue.vue';
import ArrowButton from '@/components/ArrowButton.vue';



export default {
  name: 'HomeView',

  components: {
    Icon,
    GiftCard,
    ActualityVue, 
    ArrowButton
  },

  setup () {
    const observer = ref({})
    const scrollRef = ref({})
    const headerBg = document.querySelector("header")
    const bell = document.querySelector(".sound")

    const path = process.env.VUE_APP_BASE_URL
    const temoignages = ref([])
    
   


    const promiseSayings = fetch(`${path}` + '/api/avis-clients')
    promiseSayings
    .then(response => {
     
      if(!response.ok) {
        throw Error('Aucune donnée disponible !')
      }
      return response.json()
    })
    .then(sayingsData => {
      temoignages.value = sayingsData.data
    })
    .catch (err => console.log(err))


    const onEnter = () => {
      headerBg.classList.add("nav-scrolled")
      bell.classList.add("contrast")
    }

    const onExit = () => {
      headerBg.classList.remove("nav-scrolled")
      bell.classList.remove("contrast")
    }
  
    onMounted(() => {
      observer.value = onIntersect(scrollRef.value, onEnter, onExit, false, {
        rootMargin: "-100px 0px 0px 0px",
      })

    })
    onUnmounted(() => {
      observer.value.disconnect()
    })

    return { scrollRef, observer, temoignages, path }
  }
  
};
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
  scroll-behavior: smooth;
  //margin: 2rem;
}
section {
  overflow-x: hidden;
}
#home {
  background-color: var(--color-tertiary);
  background: url('@/assets/img/oree.jpg') no-repeat center center;
  background-size: cover;

  position: relative;
}
em {
  font-family: var(--logo-font-family);
  font-size: var(--h1-font-size);
}
.home__container {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}
.home__text {
  line-height: 1.2em;
  color: white;
  font-size: var(--h2-font-size);
  font-weight: var(--font-light); 
  text-align: center;
}


.arrow.arrow-bis {
  right: 50px;

  border: 2px solid var(--color-dark);
  &::before, &::after {
    border: 2px solid var(--color-dark);
    border-top: transparent;
    border-left: transparent;
  }
}


#sayings {
  background-color: var(--color-light);
  background: url('../assets/img/chetan-kolte.jpg') no-repeat center center;
  background-size: cover;
  
  padding-bottom: 5rem;
  text-align: center;
  //box-shadow: 0 0 4px 8px #549370 inset;
  h2 {
    color: var(--color-light);
    text-align: right;
    //margin: 2em;
    padding: 1em;
  }
  .advices {
    width: 100%;
    
    display: flex;
    flex-wrap: wrap;
    //justify-content: center;
    gap: 2.5rem ;
    align-items: flex-start;
    
    margin: 1rem 1rem 2rem;
    padding: 1em 2em 4em;
    

    &__div {
      display: block;
      padding: 2rem 1rem 0;
      border-radius: 1.4rem;
     
      text-align: left;
      width: 300px;
      
      background-color: var(--color-lighter);
      opacity: .85;
      position: relative;
     
      box-shadow:  4px 4px 7px -7px rgba(0,0,0,0.75);

      .circle {
        color: white;
        background-color: var(--color-secondary);
        width: 56px;
        height: 56px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: -32px;
        left: 32px;
       
        padding-top: 10px;
      }
      blockquote {
       
        //width: 293px;        
        font-weight: var(--font-medium);
        font-size: 1.1rem;
      }
      figcaption {
        font-size: 1rem;
        font-style: italic;
        font-weight: var(--font-bold);
        padding: 1rem 0 1.5rem;
      }
    }
  }
}

.scroll-down {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid var(--color-light);
  position: absolute;
  bottom: 40px;
}
@media(max-width: 991px) {
  .home__text {
    font-size: 1.6rem; 
  }
 
  #sayings {
    // .advices {
    //   width: 100%;
     
    //   //flex-wrap: nowrap;
    // }
   
    .pagination {
      bottom: 10px  !important;
      display: block;
    }
    // .btn__CTA {
    //   position: relative;
    //   bottom: -40px;
    // }
  }
}
@media(max-width: 768px) {
  .home__container {
    align-items:flex-start;

    a {
      align-self: center;
    }
  }
  .home__text {
    padding: 0 10px 0 1rem;
    text-align: left;
    font-size: 1.4rem;
  }
  
  #sayings {
    //margin: 0 0 5rem;
    .advices {
      justify-content: center;
      padding: 1rem;
      margin: 0 0 3rem;
     
      &__div {
        width: min(450px, 100%);
        //max-height: 180px;
        
      }
      
      blockquote {
        font-size: 16px;

      }
    }
  }
}

</style>
