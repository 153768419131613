<template>
  <section  v-if="actus.length" id="actualites" >
    <h2 id="actus">En ce moment</h2>
    <CarouselVue :navigation="true" :pagination="true" :startAutoPlay="false" :timeout="5000" class="carousel"  v-slot="{ currentSlide }">
      <SpecialTheme v-for="(actu, id) in actus" :key="id" class="actuality">
    
        <div  v-show="currentSlide === id + 1" >
            <!-- <h3>{{ actu.attributes.Nom }}</h3> -->
            <div class="content">
              <img :src="`${path + actu.attributes.Photo.data.attributes.formats.medium.url}`" :alt="actu.attributes.Photo.data.attributes.alternativeText">
              <div  class="content__text ck-content" v-html="actu.attributes.Contenu"></div>
            </div>
        </div>
        
      </SpecialTheme>

    </CarouselVue>
    <router-link :to="{ name: 'Contact' }" class="btn__CTA">Contactez-nous</router-link>
  </section>

</template>

<script>
import {  ref, inject } from 'vue'
import CarouselVue from './CarouselVue.vue'
import SpecialTheme from './SpecialTheme.vue'
//import TheLoader from './TheLoader.vue'


export default {
  name: 'ActualityVue',
  components: {
    CarouselVue, 
    SpecialTheme
  },
  
  setup () {
    
    const actus = ref({})
    const path = process.env.VUE_APP_BASE_URL
    
    const qs = require('qs')

    const query = qs.stringify({
      sort: ['id:desc',],
      populate: [
        'Photo',
      ],
    }, {
      encodeValuesOnly: true,
    })
    const spin = inject('spin')
    const promiseActualities = fetch(`${path}` + `/api/actus?${query}`)

    promiseActualities
    
    .then(response => {
      //console.log(response)
      spin.value = true
      if(!response.ok) {
        throw Error('Aucune donnée disponible ! ')
      }
      return response.json()
    })
    .then(actualitiesData => {
      spin.value = false
      actus.value = actualitiesData.data
      //console.log(actus.value)
      
    })
    .catch (err => console.log(err))
    
    
  


    return {
      actus,
      path,
      //mySpinner
    
      
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: var(--color-primary);
  padding-top: 70px;
  
}

.carousel {
  position: relative;
  overflow: hidden;

  max-height: 80vh;

  height: 80vh;
  .actuality {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
    
    width: 90%;
    //height: 100vh;
   
  
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
  //gap: 2em;
  &__text {
    text-align: left;
    //font-size:21px;
    font-weight: 400;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
    padding: 0 1.5rem;
   
  }
  img {
    width: 370px;
    
  }
  
}

.btn__CTA {
  display: block;
  //margin-bottom: 5rem;
  margin: 4rem auto;
  // min-width: 80%;
}
@media(max-width: 991px) {
  
  .content {
    align-items: flex-start;
    img {
      width: 40%;
     
    }
    &__text {
      font-size: 16px;
    }
  }
  
}
@media(max-width: 768px) {
  h2 {
    padding-top: 15px;
  }
  .carousel{
    height: 850px;
    max-height: 100%;
  }
  .content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__text {
      padding: .5rem;
      //line-height: 1;
    }
    //padding-top: 0;
    img {
      width: 50%;
      height: 230px;
      object-fit: cover;
      object-position: top;
    }
  }
  .btn__CTA {
    width: 80%;
  }
  
}

</style>
