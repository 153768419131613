/**
 * @function onIntersect
 * @param  {HTMLElement} elementToWatch elementToWatch
 * @param  {function} callback       callback once element is intersecting
 * @param  {Boolen} once           if callback only run one time
 * @param  {Object} options        Intersection Observer API options
 * @return {type} observer
 */

export const onIntersect = (
    elementToWatch,
    callback,
    outCallback = () => {},
    once = true,
    options = { rootMargin: "-200px 0px 0px 0px" }
) => {
    // initiate Observer
    const observer = new IntersectionObserver(([entry]) => {
        if(entry && !entry.isIntersecting) {
            callback(entry.target)
            if(once) {
                observer.unobserve(entry.target)
            }
        } else {
            outCallback(entry.target)
        }
    }, options);
    // observe element
    observer.observe(elementToWatch);
    // return observer so it can be used in component.
    return observer;
}

export default onIntersect