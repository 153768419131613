<template>
  <div class="notfound">
    <h2>404</h2>
    <h3>Page introuvable</h3>
  </div>

</template>

<script>
 export default {
   data: () => {
     return {}
   }
 }
</script>

<style lang="scss" scoped>
.notfound {
  height: 100vh;
  z-index: 99;
}

</style>
