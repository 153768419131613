<template>
  <div class="back-loader" v-if="start">
    <div class="loading-box">
      <div class="flower-loader">
        Loading...
      </div>
    </div>
  
  </div>

</template>

<script>

export default {
  props: ["start"],
 

}

</script>

<style lang="scss" scoped>
.back-loader {
  height: 100%;
  background: var(--color-light);
  width: 100%;
  z-index: 998;
  position: fixed;
  opacity: 0.95;
}

.loading-box {
  position: absolute;
  top: 40%;
  //width: 25%;
  margin: 0 auto;
  //text-align: center;
  //background: var(--color-light);
  //z-index: 999;
  left: 50%;
  //right: 0px;
}
</style>
