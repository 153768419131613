<template>
  <HeaderCom />
  <div>
    <TheLoader :start="spin" />
    <router-view/>
  </div>
  <FooterCom />
</template>

<script setup>
import HeaderCom from '@/components/HeaderCom.vue'
import FooterCom from '@/components/FooterCom.vue'
import TheLoader from '@/components/TheLoader.vue'
import { computed, provide, reactive, ref } from 'vue'
import { useHead } from '@unhead/vue'



 // components: { HeaderCom, FooterCom, TheLoader }, 
  
 
   
    const spin = ref(false)

    const siteData = reactive({
      title: 'Pouce !',
      description: 'Des parenthèses nature pour se re-connecter à la nature et à sa nature.',
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        }
      ]
    })
    provide('spin', spin) 
   
    
  
  



</script>

<style lang="scss">


</style>


