<template>
  <div id="giftcard" class="gift">
    <div class="gift__text">
      <h2>Offrez une pause nature avec <br> <span class="title">les cartes cadeau<em> Pouce&nbsp;!</em></span></h2>
      
      <ul>
        <li v-for="(card, id) in cards" :key="id">
          <p><span>Carte {{ card?.attributes.Nom }}</span> pour 
            <router-link class="link" :to="{ name: 'Details', params: { slug: card.attributes.activity.data.attributes.Slug }}">
              {{ card.attributes.activity.data.attributes.Name }}
            </router-link>
            d’une valeur de {{ card.attributes.Valeur }}€.
          </p>
        </li>
        <li>
          <p>Et bien sûr, si votre souhait est une PARTICIPATION financière à ces activités, cela est tout à fait possible. Vous choisissez votre montant et on vous concocte une <span>Carte Cadeau sur mesure !</span></p>
        </li>
      </ul>
      <router-link :to="{ name: 'Contact' }" class="btn__CTA">Contactez-nous</router-link>
    </div>

    <div class="img-container" >
      <img class="gift__img" v-for="(card, id) in cards" :key="id" :src="`${path + card.attributes.Image?.data.attributes.formats.medium.url}`" :alt="card.attributes.Image?.data.attributes.alternativeText">
    </div>
  </div>
 
</template>

<script >
import { ref } from 'vue';
export default {
  name:'GiftCard',

  setup() {
    const path = process.env.VUE_APP_BASE_URL
    const cards = ref({})
    const error = ref({})
   
    // load data
    const load = () => {
      const promiseFaqs = fetch(`${path}` + '/api/carte-cadeaux?populate=*')

      promiseFaqs
      .then(response => {
        if (!response.ok) {
          throw Error('Aucune donnée disponible !')
        }
        return response.json()
      })
      .then(cardsData => {
        //console.log(cardsData.data)
        cards.value = cardsData.data

      })
      .catch(err => {
        error.value = err.message
        console.log(error.value)
      })


    }
    
    load()

    return {
      cards,
      error,
      path
    }

  }
}

</script>

<style lang="scss" scoped>
h2 {
  color: var(--color-light);
} 
.title {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.7;
}
span {
  font-weight: 900;
  //color: var(--color-secondary);
}
.gift {
  display: flex;
  flex-direction: row-reverse;
  align-items:center;
  gap: 4rem;
  justify-content: space-around;
  background-color: #549370;
  padding: 4rem 2rem 2rem 4.5rem ;
  
  &__img {
    width: min(400px, 100%);
    border-radius: 15px;
    //transform: rotate(-0.05turn);
  }
  &__img:nth-child(2n) {
    transform: rotate(0.05turn) translateY(-30px) translateX(-20px);
  }
  &__img:first-child {
    transform: rotate(-0.05turn) translateY(40px)
  }
  &__img:last-child {
    transform: rotate(-0.05turn) translateY(-100px)
  }
  &__text {
    color: var(--color-light);
    //padding: 0 2rem;
    ul {
      text-align: left;
      //padding: 1rem;
      font-weight: 600;
      //margin: 1rem;
      li {
        font-weight: 600;
        //padding: 1rem 0rem;
      }
    }
    .link {
      text-decoration: none;
      color: var(--color-tertiary);
      font-weight: 900;
      letter-spacing: 1px;
      position: relative;
      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom:0;
        width: 100%;
        height: 2px;
        background: currentColor;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 250ms ease-in;
      }
    }
  }

}
.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
// .gift__img:nth-child(2n) {
//   transform: translateY(-40px);
  
// }
a.btn__CTA {
  display: block;
  margin: 0 auto ;
  width: fit-content;
}
@media(max-width: 991px) {
  .gift{
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    gap: 2rem;
    
    &__text {
      margin: 1rem 1rem 0rem; 
      padding: 0 0 0rem;
      ul {
        margin: 0;
        padding-left: 2em;
      }
      p {
        font-size: 18px;
        padding: 0;
      }
    }
    
  }
  .img-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 4rem 0;
    padding: 2rem 2rem 0;
  }
  .gift__img {
    
    width: min(220px, 100%);
  }
  .gift__img:first-of-type {
    transform: translateY(-20px) translateX(140px) ;
  }
  // .gift__img:nth-child(2n){
  //   transform: translateY(0px) translateX(140px) rotate(0.05turn);
  // }
  .gift__img:last-of-type {
    transform: translateY(-20px) translateX(-160px) rotate(-0.05turn);
  }
  h2 {
    margin: 0;
    padding: 1rem;
  }
}
@media(max-width: 768px) {
  .gift {
    flex-direction: column-reverse;
    align-items: center;
    padding: 2rem 0rem;
    &__img {
      //min-width:180px !important;
      width: min(180px, 100%);
    }
    &__img:last-of-type {
      //display: none;
      transform: translateX(-130px) translateY(0px) rotate(-0.05turn);
      //transform: translateY(0px) translateX(-140px) rotate(0.05turn);
    }
    &__img:first-of-type {
      //display: none;
      transform: translateX(130px) rotate(-0.05turn);
    }
    
  }
  .gift__img:nth-child(2n){
    transform: translateX(0px) rotate(-0.05turn);
    // transform: translateY(0px) translateX(140px) rotate(0.05turn);
  }

  .img-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 ;
    padding: 2rem 2rem 0;

  }
  
  .gift__text p{
    font-size: 16px;
  }
}
</style>
