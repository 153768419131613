<template>
  <header ref="scrollRef">   
    <router-link class="logo" :to="{ name: 'Home'}">
    <!-- <img class="header__img" src="@/assets/img/Logo.jpg" alt="Logo de Pouce !"> -->
      <div>Pouce&nbsp;!</div>
    </router-link>
    <nav ref="inner_target">  
      <div v-show="!mobile" class="nav__list">
        <router-link class="nav__link" :to="{ name: 'Home' }"><Icon icon="bxs:home-circle" height="23" class="icon" /> L'Orée</router-link>
        <router-link class="nav__link" :to="{ name: 'Activities' }"><Icon icon="bxs:spreadsheet" height="23" class="icon"/> Nos branches</router-link>
        <router-link class="nav__link" :to="{ name: 'Team' }"><Icon icon="bxs:group" height="23" class="icon"/> Nos racines</router-link>
        <router-link class="nav__link" :to="{ name: 'Books' }"><Icon icon="bxs:book-reader" height="23" class="icon"/> Nos feuilles</router-link>
        <router-link class="nav__link" :to="{ name: 'Story' }" ><Icon icon="bxs:tree-alt" height="23" class="icon"/> Notre sève</router-link>
        <router-link class="nav__link" :to="{ name: 'Contact' }"><Icon icon="bxs:pencil" height="23" class="icon"/> Contact</router-link>
        <div class="sound nav__link" @click="toggleSound()">
          <Icon icon="bxs:bell-off" height="35" v-if="mute"/>
          <Icon icon="bxs:bell" height="35" v-if="!mute" />
        </div>
      </div>
      <div class="burger">
        <Icon icon="charm:menu-hamburger" class="burger__icon" @click="toggleMobileNav" v-show="mobile" :class="{ 'burger-active': mobileNav }" />
      </div>
      <audio id="myAudio">
        <source src="@/assets/audio/pouce.mp3 " loop >
      </audio>

      <transition name="mobile-nav" >
        <div v-show="mobileNav" class="dropdown-nav" >
          <router-link class="nav__link" :to="{ name: 'Home' }"><Icon icon="bxs:home-circle" height="23" class="icon" /> L'Orée</router-link>
          <router-link class="nav__link" :to="{ name: 'Activities' }"><Icon icon="bxs:spreadsheet" height="23" class="icon"/> Nos branches</router-link>
          <router-link class="nav__link" :to="{ name: 'Team' }"><Icon icon="bxs:group" height="23" class="icon"/> Nos racines</router-link>
          <router-link class="nav__link" :to="{ name: 'Books' }"><Icon icon="bxs:book-reader" height="23" class="icon"/> Nos feuilles</router-link>
          <router-link class="nav__link" :to="{ name: 'Team' }" ><Icon icon="bxs:tree-alt" height="23" class="icon"/> Notre sève</router-link>
          <router-link class="nav__link" :to="{ name: 'Contact' }"><Icon icon="bxs:pencil" height="23" class="icon"/> Contact</router-link>
        </div>
      </transition>
    </nav> 
  </header>
</template>


<script >
import { Icon } from '@iconify/vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useClickOutside } from '@/composables/useClickOutside'
//import ValuesVue from './ValuesVue.vue';

export default {
  name: 'HeaderCom',
  components: {
		Icon,
	},
  setup () {
    const mobile = ref()
    const mobileNav = ref()
    const windowWidth = ref ()
    const route = useRoute()
    const inner_target = ref(null)
    const mute = ref(true)

    
    
    const toggleSound = () => {
      if(mute.value === false) {
        mute.value = true
        document.getElementById('myAudio').muted = true
      } else {
        mute.value = false
        document.getElementById('myAudio').muted = false
        document.getElementById('myAudio').play()
      }
    }

    const toggleMobileNav = () => {
      mobileNav.value = !mobileNav.value
      mobileNav.value ? document.body.style.overflow = 'clip' : document.body.style.overflow = 'auto';
      
    }
    // const scrollToId = (id) => {
    //   console.log('clicked')
    //   document.getElementById(id).scrollIntoView;
    //   console.log(id, 'not known')
    // }

    const checkScreen = () => {
      windowWidth.value = window.innerWidth
      if (windowWidth.value <= 990) {
        //document.body.style.overflow = 'clip';
        mobile.value = true
        return
      } else if (windowWidth.value > 2000){
        //document.body.style.overflow = 'auto';
        mobile.value = false
        mobileNav.value = false
        return
      }
      document.body.style.overflow = 'auto';
      mobile.value = false
      mobileNav.value = false
      return
    }

    useClickOutside(inner_target , () => {
      mobileNav.value = false
    })

    window.addEventListener("resize", checkScreen)
    
    watch(() => [route.hash, route.name], () => {
      document.body.style.overflow = 'auto';
      mobileNav.value = false
    })

    onMounted(() => {
      window.addEventListener("resize", checkScreen)
      checkScreen()
    })
    onUnmounted(() => {
      window.removeEventListener("resize", checkScreen)
    })
    return { 
      mobile, 
      mobileNav, 
      toggleMobileNav, 
      checkScreen, 
      inner_target, 
      toggleSound, 
      mute
    }
  },
}
</script> 



<style lang="scss" scoped>

header {
  background: var(--header-bg-color); 
  color: var(--color-light);
  //max-width: 2000px;
  width: 100%;
  position: fixed;
  z-index: 35; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s ease all;
  //overflow-x: hidden;
  //border : 3px solid red;


  .logo{
    font-family: var(--logo-font-family);
    font-size: 3rem;
    font-weight: 400;
    margin: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: var(--color-light);
   // border: 3px solid blue;
    cursor: pointer;
  }

  nav {
    a {
      padding: 4px;
      margin: 0 10px;
      display:inline-flex;
      align-items: center;
      //gap: 6px;
      white-space:nowrap;
      font-weight: var(--font-bold);
      text-decoration: none;
      color: var(--color-light);
      border-bottom: 1px solid transparent;
      //border: 3px solid orange;
      position: relative;
      transition: .5s ease all;
      &:hover {
        color: var(--color-tertiary);
        border-color: var(--color-tertiary);
      }
      &.exact-active, .active {
        color: var(--color-tertiary);
        border-color: var(--color-tertiary);
      }
    }
    padding: 1rem;
  }

  
  .burger {
    display: flex;
    align-items: center;
    height: 100%;
    &__icon {
      cursor: pointer;
      font-size: 45px;
      transition: 0.8s ease all;
    }

  }
  .burger-active {
    transform: rotate(90deg);
  }
  .sound {
    cursor: pointer;
    position: absolute;
    top: 72px;
    right: 20px;
    //z-index: 99;
    color: var(--color-light);
    padding-top: 8px;
    //border: 2px solid yellow;
    //color: var(--color-light);
   
  }
  .dropdown-nav {
    padding-top: 20px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    max-width: 250px;
    height: 100%;
    background-color: var(--color-primary);
    top: 0;
    left: 0;
    a {
      margin: 10px 20px;
    }
  }

}
a.nav__link {
  //display: flex;
  //flex-direction: row;
  align-items: center;
}
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}
.mobile-nav-enter-from, 
.mobile-nav-leave-to {
  transform : translateX(-250px);
}
.mobile-nav-enter-to {
  transform : translateX(0);
}

@media(max-width: 768px) {
  header >.logo >.logo{
    font-size: 2.5rem;
    padding-top: 8px;
  }
  .sound {
    position: static;
    color: var(--color-light);
    .iconify {
      width: 32px;
      height: 32px;
    }
  }
}

</style>
